import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Modal } from "react-bootstrap";
import moment from 'moment';
import { obtenerPersonaExtranjeraApi } from '../api/contratacion/datosPersonaExtranjeraApi';
import { aprobarSolicitudVacanteApi } from '../api/contratacion/solicitudVacanteApi';
import { obtenerDatosPersonalesApi } from '../api/contratacion/datosPersonalesApi';
import { guardarDatosPersonalesApi } from '../api/contratacion/datosPersonalesApi';
import { obtenerDatosBancariosApi } from '../api/contratacion/datosBancariosApi';
import { guardarDatosPrevisionApi } from '../api/contratacion/datosPrevisionApi';
import { guardarDatosBancariosApi } from '../api/contratacion/datosBancariosApi';
import { guardarDatosLaboralesApi } from '../api/contratacion/datosLaboralesApi';
import { obtenerDatosPrevisioApi } from '../api/contratacion/datosPrevisionApi';
import { guardarDatosPersonaExtranjeraApi } from "../api/contratacion/datosPersonaExtranjeraApi";
import { obtenerVacanteApi } from '../api/contratacion/solicitudVacanteApi';
import { obtenerDireccionApi } from '../api/contratacion/datosDireccionApi';
import FormDatosPersonales from './procesoContratacion/FormDatosPersonales';
import { guardarDireccionApi } from '../api/contratacion/datosDireccionApi';
import { guardarVacanteApi } from '../api/contratacion/solicitudVacanteApi';
import FormDatosBancarios from './procesoContratacion/FormDatosBancarios';
import FormDatosDireccion from './procesoContratacion/FormDatosDireccion';
import FormDatosPrevision from './procesoContratacion/FormDatosPrevision';
import { obtenerDatosSaludApi } from '../api/contratacion/datosSaludApi';
import { guardarDatosSaludApi } from '../api/contratacion/datosSaludApi';
import { obtenerDocumentoApi } from '../api/contratacion/documentosApi';
import { guardarDocumentoApi } from '../api/contratacion/documentosApi';
import FormDocumentos from './procesoContratacion/FormDocumentos';
import { DOCUMENTOS } from '../utils/Constants';
import { SpinnerModal } from './ui/Spinner';
import { HtmlList } from './ui/HtmlList';
import { post } from '../api/apis';
import { DOMINIOS_VALIDOS } from '../utils/Constants';
import { modalCargando, modalComentario } from './ui/sweetAlerts';

import { useDatosPersonalesExtranjero } from "./procesoContratacion/useDatosPersonalesExtranjero.js";
import { useDatosPersonales } from "./procesoContratacion/useDatosPersonales.js";
import { useDatosDireccion } from "./procesoContratacion/useDatosDireccion.js";
import { useDatosLaborales } from "./procesoContratacion/useDatosLaborales.js";
import { useDatosBancarios } from "./procesoContratacion/useDatosBancarios.js";
import { useDatosPrevision } from "./procesoContratacion/useDatosPrevision.js";
import { useDocumentos } from "./procesoContratacion/useDocumentos.js";


export const EditarContratacionScreen = () => {
  const { state } = useLocation();
  const [visibleSpinner, setVisibleSpinner] = useState(true);
  const [datosExtranjero, setDatosExtranjero] = useState([]);
  const [datosLaborales, setDatosLaborales] = useState([]);
  const [datosDireccion, setDatosDireccion] = useState([]);
  const [datosBancarios, setDatosBancarios] = useState([]);
  const [datosPrevision, setDatosPrevision] = useState([]);
  const [datosDocumento, setdatosDocumento] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [listaGuardado, setListaGuardado] = useState([]);
  const [datosPersona, setDatosPersona] = useState({});
  const [guardadoTemporal, setGuardadoTemporal] = useState(false);
  const [datosSalud, setDatosSalud] = useState([]);
  const [txtSpinner, setTxtSpinner] = useState('');
  const navigate = useNavigate();
  const params = useParams();
  const msjCorrecto = ", guardado correctamente.";
  const msjIncorrecto = ", no se pudo guardar.";

  const { datosExtranjeroErr, validatePersonalesExtranjero } = useDatosPersonalesExtranjero();
  const { documentosErr, validateDocumentos, objDocumentos } = useDocumentos();
  const { datosPersonalesErr, validatePersonales } = useDatosPersonales();
  const { datosDireccionErr, validateDireccion } = useDatosDireccion();
  const { datosLaboralesErr, validateLaborales } = useDatosLaborales();
  const { datosPrevisionErr, validatePrevision } = useDatosPrevision();
  const { datosBancariosErr, validateBancarios } = useDatosBancarios();

  function setearMensaje(tipo, resp) {
    setListaGuardado(
      (listaGuardado) => [
        ...listaGuardado,
        {
          icono: `${resp.status === 'success' ? 'check.png' : 'warning.png'}`,
          texto: `${tipo}${resp.status === 'success' ? msjCorrecto : msjIncorrecto}`
        }
      ]
    );
  }

  useEffect(() => { obtenerInfo() }, []);
  useEffect(() => { setGuardadoTemporal(state.solicitud.guardadoTemporal !== 0 ? true : false) }, [state])

  const { idSolicitud } = params;

  const saveNewDataFrom = async (values, personaSolicitante = false, editaVacante = true, observaciones) => {
    setVisibleModal(!visibleModal);

    setTxtSpinner(() => setTxtSpinner('Datos personales'));
    let resp = await guardarDatosPersonalesApi(values.datosPersonales);
    setearMensaje('Datos personales', resp);

    const idPersona = resp.data.id_persona;

    if (values.datosPersonales.nacionalidad != '1') {
      setTxtSpinner(() => setTxtSpinner('Datos extranjería'));
      const respExtranjero = await guardarDatosPersonaExtranjeraApi(values.datosPersonaExtranjera, idPersona);
      setearMensaje('Datos extranjería', respExtranjero);
    }

    setTxtSpinner(() => setTxtSpinner('Datos bancarios'));
    resp = await guardarDatosBancariosApi(values.datosBancarios, idPersona);
    setearMensaje('Datos bancarios', resp);

    setTxtSpinner(() => setTxtSpinner('Datos previsión'));
    resp = await guardarDatosPrevisionApi(values.datosPrevision, idPersona);
    setearMensaje('Datos previsión', resp);

    setTxtSpinner(() => setTxtSpinner('Datos salud'));
    resp = await guardarDatosSaludApi(values.datosPrevision, idPersona);
    setearMensaje('Datos salud', resp);

    setTxtSpinner(() => setTxtSpinner('Datos dirección'));
    resp = await guardarDireccionApi(values.datosDireccion, idPersona);
    setearMensaje('Datos dirección', resp);

    await Promise.all(DOCUMENTOS.map(async (dataDocumento) => {
      if (typeof (values.datosDocumentos[dataDocumento.documento]) === 'object') {
        setTxtSpinner(() => setTxtSpinner('Documento'));
        const respDocumento = await guardarDocumentoApi(values.datosDocumentos[dataDocumento.documento], idPersona, dataDocumento['id_tipo'])
        setearMensaje('Documento', respDocumento);
      }
    }));




    if (guardadoTemporal) {
      setTxtSpinner(() => setTxtSpinner('Solicitud'));
      const resp = await post('solicitud', null, {
        id_persona: idPersona,
        guardado_temporal: 0,
        trabajador_nuevo: datosPersona.reingreso === 1 ? 0 : 1, // se refiere a si el proceso ya se encuentra en proceso o se esta ingresando los datos de un trabjador nuevo sin necesidad de ser aprobado por rrhh 
      });
      setearMensaje('Solicitud', resp.data);
    }

    if (personaSolicitante && editaVacante) {
      let ingresarDatosLAborales = false;

      if (ingresarDatosLAborales || personaSolicitante.idSolicitud) {
        setTxtSpinner(() => setTxtSpinner('Datos laborales'));
        const respDatosLaborales = await guardarDatosLaboralesApi(values.datosLaborales, idSolicitud);
        setearMensaje('Datos laborales', respDatosLaborales);

        if (personaSolicitante.idSolicitud) {
          setTxtSpinner(() => setTxtSpinner('Vacante'));
          const respSolicitante = await aprobarSolicitudVacanteApi(idSolicitud);
          setearMensaje('Vacante', respSolicitante);
        }

        if (respDatosLaborales.status === "success") { setTimeout(() => { window.location = "/" }, 300000); }
      }
    }

    if (localStorage.getItem('id_perfil') === '4' && guardadoTemporal === false) {
      setTxtSpinner(() => setTxtSpinner('Enviando reconsideración'));
      const respConsideracion = await post('envioreconsideracion', { idSolicitud: state.idSolicitud }, { observaciones: observaciones });

      if (respConsideracion.data.status === 'success') {
        setearMensaje('Reconsideración', respConsideracion.data);
      }
    }

    setVisibleSpinner(!visibleSpinner);
  }

  const obtenerInfo = async () => {
    modalCargando('Obteniendo datos...');
    await obtenerDatosPersonalesApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosPersona(res.data)
      }).catch(error => console.log("Error :", error))
    await obtenerVacanteApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosLaborales(res.data)
      }).catch(error => console.log("Error :", error))
    await obtenerPersonaExtranjeraApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosExtranjero(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDatosPrevisioApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosPrevision(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDatosSaludApi(state.idPersonaSolicitud, state.idSolicitud)
      .then(res => {
        if (res.status === "success") setDatosSalud(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDireccionApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosDireccion(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDatosBancariosApi(state.idPersonaSolicitud)
      .then(res => {
        if (res.status === "success") setDatosBancarios(res.data)
      }).catch(error => console.log("Error :", error))

    await obtenerDocumentoApi(state.idPersonaSolicitud).then(res => {
      if (res.status === "success") setdatosDocumento(res.data)
    }).catch(error => console.log("Error :", error))
    Swal.close()
  }

  const validate = (values) => {
    let errors = {
      datosLaborales: datosLaboralesErr,
      datosPersonales: datosPersonalesErr,
      datosPersonaExtranjera: datosExtranjeroErr,
      datosDireccion: datosDireccionErr,
      datosPrevision: datosPrevisionErr,
      datosBancarios: datosBancariosErr,
      datosDocumentos: documentosErr,
    };

    const { nacionalidad } = values.datosPersonales;
    const { tipoVisa } = values.datosPersonaExtranjera;
    const { jubilado } = values.datosPrevision;
    const { certificadoCovid } = values.datosPrevision;

    validateLaborales(values.datosLaborales);
    validatePersonales(values.datosPersonales);
    validatePersonalesExtranjero({ ...values.datosPersonaExtranjera, nacionalidad });
    validateDireccion(values.datosDireccion);
    validatePrevision(values.datosPrevision);
    validateBancarios(values.datosBancarios);
    validateDocumentos({ ...values.datosDocumentos, certificadoCovid, tipoVisa, jubilado, nacionalidad })

    const noHayErrores = Object.values(errors).every(el => Object.values(el).length === 0);
    return noHayErrores ? {} : errors;
  };

  const acccionVerVacante = async (idSolicitud, idPersonaSolicitud, solicitud) => {
    return navigate(`/vacante/${idSolicitud}`, { state: { idSolicitud, idPersonaSolicitud, solicitud } });
  }

  const formik = useFormik({
    enableReinitialize: true,
    validate,
    initialValues: {
      datosLaborales: {
        fechaIngreso: datosLaborales.fecha_ingreso || '',
        cargo: datosLaborales.nombre_vacante || '',
        planHorario: datosLaborales.temporis_plan_horario || '',
        unidadOrganizativaRolTemporis: "",
        cargoRolTemporis: "",
        tipoContrato: datosLaborales.tipo_contrato || '',
        fechaFinContrato: datosLaborales.fecha_retiro || '',
      },
      datosPersonales: {
        rut: datosPersona.identificacion || '',
        id_persona: datosPersona.id || '',
        nombres: datosPersona.nombre || '',
        apellidoPaterno: datosPersona.apellido_pat || '',
        apellidoMaterno: datosPersona.apellido_mat || '',
        fechaNacimiento: datosPersona.fecha_nacimiento || '',
        sexo: datosPersona.id_persona_genero || '',
        estadoCivil: datosPersona.id_persona_estado_civil || '',
        nivelFormacion: datosPersona.id_educacion_nivel || '',
        nacionalidad: datosPersona.id_pais || '',
        email: datosPersona.correo || '',
        telefonoCelular: datosPersona.telefono || '',
      },
      datosPersonaExtranjera: {
        tipoVisa: datosExtranjero.id_persona_tipo_visa || '',
        visaTemporariaDesde: datosExtranjero.fecha_visa_temporaria || '',
        visaTemporariaHasta: datosExtranjero.fecha_vencimiento_visa || '',
      },
      datosDireccion: {
        calle: datosDireccion.direccion || "",
        numeroCasa: datosDireccion.numero || "",
        numeroDepartamento: datosDireccion.numero_departamento || "",
        region: datosDireccion.id_region || "",
        comuna: datosDireccion.id_comuna || "",
        pais: datosDireccion.id_pais || "",
        tipoDireccion: datosDireccion.id_tipo_direccion || ""
      },
      datosPrevision: {
        jubilado: datosPrevision.jubilado?.toString(),
        previsionSalud: datosSalud.id_institucion_salud || '',
        previsionSocial: datosPrevision.id_afp || '',
        planPactado: datosSalud.valor_plan || 0,
        certificadoCovid: datosSalud.certificaco_covid || 0,
        certificadoDiscapacidad: datosSalud.certificaco_discapacidad || 0
      },
      datosBancarios: {
        formaDePago: datosBancarios.tipo_pago || '',
        banco: datosBancarios.id_banco || '',
        tipoCuenta: datosBancarios.id_tipo_cuenta || '',
        numeroCuenta: datosBancarios.numero_cuenta || '',
      },
      datosDocumentos: objDocumentos(datosDocumento),
    },
    onSubmit: (values, comentario) => {
      saveNewDataFrom(values, false, false)
    }
  });

  useEffect(() => {
    formik.setErrors(validate(formik.values));
  }, [formik.values]);

  return (
    <>
      <Modal show={visibleModal}>
        <HtmlList lista={listaGuardado} />

        {visibleSpinner && <SpinnerModal txt={txtSpinner} />}

        {!visibleSpinner &&
          <>
            <div className="text-center">
              <button
                className="btnArcoprime mb-4 mt-2"
                onClick={() => {
                  setVisibleSpinner(!visibleSpinner);
                  setVisibleModal(!visibleModal);
                  acccionVerVacante(state.idSolicitud, state.idPersonaSolicitud, state);
                }}
              >Entendido</button>
            </div>
          </>
        }
      </Modal>

      <div className='mx-5 px-5'>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos Personales</h3>
          </div>
          <div className="card-body">
            <FormDatosPersonales formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Dirección</h3>
          </div>
          <div className="card-body">
            <FormDatosDireccion formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos previsión</h3>
          </div>
          <div className="card-body">
            <FormDatosPrevision formik={formik} />
          </div>
        </div>
        <div className="card my-4">
          <div className="card-header bgArcopime">
            <h3 className='my-0'>Datos Bancarios</h3>
          </div>
          <div className="card-body">
            <FormDatosBancarios formik={formik} />
          </div>
        </div>
        <div className="card my-2">
          <div className="card-body">
            <h3>Subir Documentos nuevos</h3>
            <FormDocumentos formik={formik} />
          </div>
        </div>
        <div className="card my-2">
          <div className="card-body">
            <h3>Documentos cargados</h3>
            <div className='row'>
              {
                datosDocumento.map((documento) => (
                  <div className='col-lg-3 col-sm-3'>
                    <div className="card border-dark mb-3">
                      <div className="card-header">{documento.documento}</div>
                      <div className="card-body text-dark">
                        <a target="_blank" rel="noreferrer" href={documento.url} className="btn btn-outline-success">ver documento</a>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button
            className="btn btn-outline-primary me-md-2"
            type="button"
            onClick={() => { navigate(-1) }}
          >Volver</button>
          <button
            disabled={JSON.stringify(formik.errors) !== '{}'}
            className="btn btn-primary"
            type="button"
            onClick={() => {
              const txtTitulo = localStorage.getItem('id_perfil') === '4' ? 'Enviar Reconsideración' : 'Guardar'
              if (((localStorage.getItem('id_perfil') === '4') && guardadoTemporal === false)) {
                modalComentario(saveNewDataFrom, txtTitulo, formik.values);
              }
              if ((localStorage.getItem('id_perfil') === '3') || ((localStorage.getItem('id_perfil') === '4') && guardadoTemporal === true)) {
                saveNewDataFrom(formik.values)
              }
            }}
          >
            {((localStorage.getItem('id_perfil') === '4') && guardadoTemporal === false) && "Enviar Reconsideración"}

            {((localStorage.getItem('id_perfil') === '3') || ((localStorage.getItem('id_perfil') === '4') && guardadoTemporal === true)) && "Guardar"}
          </button>
        </div>
      </div>
    </>
  )
}
